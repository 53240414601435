import React from "react";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ImageCollection,
  Contact,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import { getPageHeroImages, getPageGalleryImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Home</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={data.businessName}
              tagline={""}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[
                {
                  text: "Order Online",
                  link: "https://ordering.chownow.com/order/27667/locations",
                  internal: false,
                  className: "chownow-order-online",
                },
                {
                  text: "Order Catering",
                  link: "https://www.ezcater.com/brand/pvt/cayden-gs",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={"100vh"}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={<Markdown>{data.aboutMarkdown}</Markdown>}
                header={`Welcome to ${data.businessName}`}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                as={"carousel"}
                centered={true}
                cutUnevenRows={true}
                disable={"none"}
                header={"Gallery"}
                images={getPageGalleryImages(pageComponents)}
                numberOfRowDisplayItems={4}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable className="component-section-container">
            <Grid.Column width={16}>
              <Contact
                phoneNumber={data.phone}
                email={data.email}
                address={{
                  street: data.address.street,
                  city: data.address.city,
                  state: data.address.state,
                  zip: data.address.zip,
                }}
                hours={data.hours}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
          hours {
            day
          }
        }
      }
    }
    fishermanWebsitePage(pageType: { eq: "Home" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
